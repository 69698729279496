import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ArtAccessoryGroupsService } from './api/artAccessoryGroups.service';
import { ArtAccessoryRulesService } from './api/artAccessoryRules.service';
import { ArtAnswersService } from './api/artAnswers.service';
import { ArtArticlesService } from './api/artArticles.service';
import { ArtAttenuatorArticlesService } from './api/artAttenuatorArticles.service';
import { ArtBlockedArticlesService } from './api/artBlockedArticles.service';
import { ArtCategoriesService } from './api/artCategories.service';
import { ArtComfothermArticlesService } from './api/artComfothermArticles.service';
import { ArtDeviceGroupsService } from './api/artDeviceGroups.service';
import { ArtDeviceRulesService } from './api/artDeviceRules.service';
import { ArtDiscountPresetsService } from './api/artDiscountPresets.service';
import { ArtDistributionsService } from './api/artDistributions.service';
import { ArtDuctArticlesService } from './api/artDuctArticles.service';
import { ArtDuctConversionArticlesService } from './api/artDuctConversionArticles.service';
import { ArtDuctDiameterCapacitiesService } from './api/artDuctDiameterCapacities.service';
import { ArtDuctRegulatorArticlesService } from './api/artDuctRegulatorArticles.service';
import { ArtDuctSplitterArticlesService } from './api/artDuctSplitterArticles.service';
import { ArtDuctTypesService } from './api/artDuctTypes.service';
import { ArtEndPlateArticlesService } from './api/artEndPlateArticles.service';
import { ArtFilterArticlesService } from './api/artFilterArticles.service';
import { ArtFilterBoxArticlesService } from './api/artFilterBoxArticles.service';
import { ArtInstallationDefaultsService } from './api/artInstallationDefaults.service';
import { ArtManifoldArticlesService } from './api/artManifoldArticles.service';
import { ArtManifoldBoxArticlesService } from './api/artManifoldBoxArticles.service';
import { ArtMountingKitArticlesService } from './api/artMountingKitArticles.service';
import { ArtOutletGroupsService } from './api/artOutletGroups.service';
import { ArtOutletRulesService } from './api/artOutletRules.service';
import { ArtOutsideAirArticlesService } from './api/artOutsideAirArticles.service';
import { ArtPricesService } from './api/artPrices.service';
import { ArtQuestionsService } from './api/artQuestions.service';
import { ArtReplacementsService } from './api/artReplacements.service';
import { ArtSetMaterialsService } from './api/artSetMaterials.service';
import { ArtSetsService } from './api/artSets.service';
import { ArtTextsService } from './api/artTexts.service';
import { ArtTubeArticlesService } from './api/artTubeArticles.service';
import { ArtTubeTypesService } from './api/artTubeTypes.service';
import { ArtVpesService } from './api/artVpes.service';
import { ArticleExportsService } from './api/articleExports.service';
import { ArticleImportsService } from './api/articleImports.service';
import { ArticleTransfersService } from './api/articleTransfers.service';
import { AuthService } from './api/auth.service';
import { C4cService } from './api/c4c.service';
import { C4cImportsService } from './api/c4cImports.service';
import { CcfService } from './api/ccf.service';
import { CcfConfigurationPresetsService } from './api/ccfConfigurationPresets.service';
import { ConfigService } from './api/config.service';
import { ConstructionTypesService } from './api/constructionTypes.service';
import { CoreExportsService } from './api/coreExports.service';
import { CoreImportsService } from './api/coreImports.service';
import { DebugService } from './api/debug.service';
import { EnerventService } from './api/enervent.service';
import { ExchangeRatesService } from './api/exchangeRates.service';
import { FloorPlanRoomsService } from './api/floorPlanRooms.service';
import { FloorPlansService } from './api/floorPlans.service';
import { IntegrityReportsService } from './api/integrityReports.service';
import { LinksService } from './api/links.service';
import { MbusService } from './api/mbus.service';
import { MediasService } from './api/medias.service';
import { MtlService } from './api/mtl.service';
import { MtlConfigurationPresetsService } from './api/mtlConfigurationPresets.service';
import { NewsService } from './api/news.service';
import { NotificationEmailTemplatesService } from './api/notificationEmailTemplates.service';
import { PdfAdditionalPdfsService } from './api/pdfAdditionalPdfs.service';
import { PdfAdditionalTextsService } from './api/pdfAdditionalTexts.service';
import { PdfEmailTemplatesService } from './api/pdfEmailTemplates.service';
import { PdfSectionsService } from './api/pdfSections.service';
import { PermissionsService } from './api/permissions.service';
import { PrivateCalculationMethodsService } from './api/privateCalculationMethods.service';
import { PrjAccessoriesService } from './api/prjAccessories.service';
import { PrjBuildingInstallationsService } from './api/prjBuildingInstallations.service';
import { PrjCpdService } from './api/prjCpd.service';
import { PrjDeviceService } from './api/prjDevice.service';
import { PrjDocumentsService } from './api/prjDocuments.service';
import { PrjInsideInstallationsService } from './api/prjInsideInstallations.service';
import { PrjManifoldsService } from './api/prjManifolds.service';
import { PrjMaterialsService } from './api/prjMaterials.service';
import { PrjOutletsService } from './api/prjOutlets.service';
import { PrjOutsideInstallationsService } from './api/prjOutsideInstallations.service';
import { PrjPlanningService } from './api/prjPlanning.service';
import { PrjProjectsService } from './api/prjProjects.service';
import { PrjStakeholdersService } from './api/prjStakeholders.service';
import { PrjUploadsService } from './api/prjUploads.service';
import { RolesService } from './api/roles.service';
import { RoomVariantsService } from './api/roomVariants.service';
import { SapImportsService } from './api/sapImports.service';
import { SettingsService } from './api/settings.service';
import { TranslationsService } from './api/translations.service';
import { UserGroupsService } from './api/userGroups.service';
import { UsersService } from './api/users.service';
import { ZipCodesService } from './api/zipCodes.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
