import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { PermissionName } from '@api';
import { RedirectComponent } from '@shared/redirect/redirect.component';

const routes: Routes = [
  Shell.childRoutes(
    [
      {
        path: 'management/art',
        loadChildren: () => import('./management/art/art.module').then((m) => m.ArtModule),
      },
      {
        path: 'management/article_exports',
        loadChildren: () => import('./management/article-exports/records.module').then((m) => m.RecordsModule),
      },
      {
        path: 'management/article_imports',
        loadChildren: () => import('./management/article-imports/records.module').then((m) => m.RecordsModule),
      },
      {
        path: 'management/integrity_reports',
        loadChildren: () => import('./management/integrity-reports/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManageArticles },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/article_transfers',
        loadChildren: () => import('./management/article-transfers/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManageArticleTransfers },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/c4c_imports',
        loadChildren: () => import('./management/c4c-imports/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManageC4cImports },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/ccf_configuration_presets',
        loadChildren: () =>
          import('./management/ccf-configuration-presets/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManageArticles },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/construction_types',
        loadChildren: () => import('./management/construction-types/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManagePlanning },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/exchange_rates',
        loadChildren: () => import('./management/exchange-rates/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManageExchangeRates },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/floor_plan_rooms',
        loadChildren: () => import('./management/floor-plan-rooms/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManagePlanning },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/floor_plans',
        loadChildren: () => import('./management/floor-plans/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManagePlanning },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/links',
        loadChildren: () => import('./management/links/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManageLinks },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/medias',
        loadChildren: () => import('./management/medias/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManagePictures },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/mbus',
        loadChildren: () => import('./management/mbus/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManageMbus },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/mtl_configuration_presets',
        loadChildren: () =>
          import('./management/mtl-configuration-presets/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManageArticles },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/news',
        loadChildren: () => import('./management/news/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManageNews },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/notification_email_templates',
        loadChildren: () =>
          import('./management/notification-email-templates/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManageNotificationEmailTemplates },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/pdf_sections',
        loadChildren: () => import('./management/pdf-sections/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManagePdfSections },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/pdf_additional_texts',
        loadChildren: () => import('./management/pdf-additional-texts/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManagePdfAdditionalTexts },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/pdf_additional_pdfs',
        loadChildren: () => import('./management/pdf-additional-pdfs/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManagePdfAdditionalPdfs },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/pdf_email_templates',
        loadChildren: () => import('./management/pdf-email-templates/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManagePdfEmailTemplates },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/private_calculation_methods',
        loadChildren: () =>
          import('./management/private-calculation-methods/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManagePlanning },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/roles',
        loadChildren: () => import('./management/roles/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManageRoles },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/room_variants',
        loadChildren: () => import('./management/room-variants/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManagePlanning },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/sap_imports',
        loadChildren: () => import('./management/sap-imports/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManageSapImports },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/settings',
        loadChildren: () => import('./management/settings/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManageSettings },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/translations',
        loadChildren: () => import('./management/translations/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManageTranslations },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/core_exports',
        loadChildren: () => import('./management/core-exports/records.module').then((m) => m.RecordsModule),
      },
      {
        path: 'management/core_imports',
        loadChildren: () => import('./management/core-imports/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManageCoreBackups },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/users',
        loadChildren: () => import('./management/users/users.module').then((m) => m.UsersModule),
      },
    ],
    { permission: PermissionName.ManageUsers },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/user_groups',
        loadChildren: () => import('./management/user-groups/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManageUserGroups },
  ),
  Shell.childRoutes(
    [
      {
        path: 'management/zip_codes',
        loadChildren: () => import('./management/zip-codes/records.module').then((m) => m.RecordsModule),
      },
    ],
    { permission: PermissionName.ManagePlanning },
  ),
  Shell.childRoutes([
    {
      path: 'projects/:project_id/details',
      loadChildren: () => import('./projects/details/details.module').then((m) => m.DetailsModule),
    },
    {
      path: 'projects/:project_id/planning',
      loadChildren: () => import('./projects/planning/planning.module').then((m) => m.PlanningModule),
    },
    {
      path: 'projects/:project_id/device/:building_id', // the building is always present
      loadChildren: () => import('./projects/device/device.module').then((m) => m.DeviceModule),
    },
    {
      path: 'projects/:project_id/accessories/:building_id', // the building is always present
      loadChildren: () => import('./projects/accessories/accessories.module').then((m) => m.AccessoriesModule),
    },
    {
      path: 'projects/:project_id/outlets/:building_id/:apartment_id', // the building and apartment are always present
      loadChildren: () => import('./projects/outlets/outlets.module').then((m) => m.OutletsModule),
    },
    {
      path: 'projects/:project_id/manifolds/:building_id/:apartment_id', // the building and apartment are always present
      loadChildren: () => import('./projects/manifolds/manifolds.module').then((m) => m.ManifoldsModule),
    },
    {
      path: 'projects/:project_id/inside_installations/:building_id/:apartment_id', // the building and apartment are always present
      loadChildren: () =>
        import('./projects/inside-installations/inside-installations.module').then((m) => m.InsideInstallationsModule),
    },
    {
      path: 'projects/:project_id/building_installations/:building_id', // the building is always present
      loadChildren: () =>
        import('./projects/building-installations/building-installations.module').then(
          (m) => m.BuildingInstallationsModule,
        ),
    },
    {
      path: 'projects/:project_id/outside_installations/:building_id', // the building is always present
      loadChildren: () =>
        import('./projects/outside-installations/outside-installations.module').then(
          (m) => m.OutsideInstallationsModule,
        ),
    },
    {
      path: 'projects/:project_id/materials',
      loadChildren: () => import('./projects/materials/materials.module').then((m) => m.MaterialsModule),
    },
    {
      path: 'projects/:project_id/documents',
      loadChildren: () => import('./projects/documents/documents.module').then((m) => m.DocumentsModule),
    },
  ]),
  Shell.childRoutes(
    [
      {
        path: 'unavailable',
        loadChildren: () => import('./unavailable/unavailable.module').then((m) => m.UnavailableModule),
      },
    ],
    {
      header: false,
    },
  ),
  { path: 'redirect', component: RedirectComponent },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
