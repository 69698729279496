/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ErrorForbidden } from '../model/models';
import { ErrorNotFound } from '../model/models';
import { ErrorUnauthorized } from '../model/models';
import { ErrorValidationResponse } from '../model/models';
import { InlineResponse200109 } from '../model/models';
import { InlineResponse20086 } from '../model/models';
import { PrjProjectCollapseInput } from '../model/models';
import { PrjProjectInput } from '../model/models';
import { PrjProjectsList } from '../model/models';
import { PrjProjectsListCategory } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PrjProjectsService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Project collapse
     * Project collapse
     * @param projectId ID of project
     * @param prjProjectCollapseInput Pass project details
     * @param projectAccessedAt Project updated at
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param excludeText Exclude text
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjProjectCollapse(projectId: number, prjProjectCollapseInput: PrjProjectCollapseInput, projectAccessedAt?: string, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20086>;
    public prjProjectCollapse(projectId: number, prjProjectCollapseInput: PrjProjectCollapseInput, projectAccessedAt?: string, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20086>>;
    public prjProjectCollapse(projectId: number, prjProjectCollapseInput: PrjProjectCollapseInput, projectAccessedAt?: string, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20086>>;
    public prjProjectCollapse(projectId: number, prjProjectCollapseInput: PrjProjectCollapseInput, projectAccessedAt?: string, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling prjProjectCollapse.');
        }
        if (prjProjectCollapseInput === null || prjProjectCollapseInput === undefined) {
            throw new Error('Required parameter prjProjectCollapseInput was null or undefined when calling prjProjectCollapse.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (projectAccessedAt !== undefined && projectAccessedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>projectAccessedAt, 'project_accessed_at');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (excludeText !== undefined && excludeText !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeText, 'exclude_text');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<InlineResponse20086>(`${this.configuration.basePath}/api/prj/projects/${encodeURIComponent(String(projectId))}/collapse`,
            prjProjectCollapseInput,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Project create
     * Project create
     * @param prjProjectInput Pass project details
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjProjectCreate(prjProjectInput: PrjProjectInput, fields?: string, relations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20086>;
    public prjProjectCreate(prjProjectInput: PrjProjectInput, fields?: string, relations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20086>>;
    public prjProjectCreate(prjProjectInput: PrjProjectInput, fields?: string, relations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20086>>;
    public prjProjectCreate(prjProjectInput: PrjProjectInput, fields?: string, relations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (prjProjectInput === null || prjProjectInput === undefined) {
            throw new Error('Required parameter prjProjectInput was null or undefined when calling prjProjectCreate.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<InlineResponse20086>(`${this.configuration.basePath}/api/prj/projects`,
            prjProjectInput,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Project delete
     * Project delete
     * @param projectId ID of project
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjProjectDelete(projectId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public prjProjectDelete(projectId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public prjProjectDelete(projectId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public prjProjectDelete(projectId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling prjProjectDelete.');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/prj/projects/${encodeURIComponent(String(projectId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Project details
     * Project details
     * @param projectId ID of project
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param withTrashed With trashed
     * @param includeReadonly Include readonly
     * @param includeHasExpiredPriceDate Include has expired price date
     * @param includeHasArticles Include has articles
     * @param includeHasUnavailableArticles Include has unavailable articles
     * @param includeHasCcfConfigurations Include has CCF configurations
     * @param includeHasUnavailableCcfConfigurations Include has unavailable CCF configurations
     * @param includeHasCustomCcfConfigurations Include has custom CCF configurations
     * @param includeHasMtlConfigurations Include has metal manifold configurations
     * @param includeHasUnavailableMtlConfigurations Include has unavailable metal manifold configurations
     * @param includeUploadsMediaSources Include project uploads media sources
     * @param includeCpdData Include project CPD media sources and data
     * @param includePdfSections Include pdf_sections
     * @param excludeText Exclude text
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjProjectDetails(projectId: number, fields?: string, relations?: string, withTrashed?: boolean, includeReadonly?: boolean, includeHasExpiredPriceDate?: boolean, includeHasArticles?: boolean, includeHasUnavailableArticles?: boolean, includeHasCcfConfigurations?: boolean, includeHasUnavailableCcfConfigurations?: boolean, includeHasCustomCcfConfigurations?: boolean, includeHasMtlConfigurations?: boolean, includeHasUnavailableMtlConfigurations?: boolean, includeUploadsMediaSources?: boolean, includeCpdData?: boolean, includePdfSections?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20086>;
    public prjProjectDetails(projectId: number, fields?: string, relations?: string, withTrashed?: boolean, includeReadonly?: boolean, includeHasExpiredPriceDate?: boolean, includeHasArticles?: boolean, includeHasUnavailableArticles?: boolean, includeHasCcfConfigurations?: boolean, includeHasUnavailableCcfConfigurations?: boolean, includeHasCustomCcfConfigurations?: boolean, includeHasMtlConfigurations?: boolean, includeHasUnavailableMtlConfigurations?: boolean, includeUploadsMediaSources?: boolean, includeCpdData?: boolean, includePdfSections?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20086>>;
    public prjProjectDetails(projectId: number, fields?: string, relations?: string, withTrashed?: boolean, includeReadonly?: boolean, includeHasExpiredPriceDate?: boolean, includeHasArticles?: boolean, includeHasUnavailableArticles?: boolean, includeHasCcfConfigurations?: boolean, includeHasUnavailableCcfConfigurations?: boolean, includeHasCustomCcfConfigurations?: boolean, includeHasMtlConfigurations?: boolean, includeHasUnavailableMtlConfigurations?: boolean, includeUploadsMediaSources?: boolean, includeCpdData?: boolean, includePdfSections?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20086>>;
    public prjProjectDetails(projectId: number, fields?: string, relations?: string, withTrashed?: boolean, includeReadonly?: boolean, includeHasExpiredPriceDate?: boolean, includeHasArticles?: boolean, includeHasUnavailableArticles?: boolean, includeHasCcfConfigurations?: boolean, includeHasUnavailableCcfConfigurations?: boolean, includeHasCustomCcfConfigurations?: boolean, includeHasMtlConfigurations?: boolean, includeHasUnavailableMtlConfigurations?: boolean, includeUploadsMediaSources?: boolean, includeCpdData?: boolean, includePdfSections?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling prjProjectDetails.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (withTrashed !== undefined && withTrashed !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>withTrashed, 'with_trashed');
        }
        if (includeReadonly !== undefined && includeReadonly !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeReadonly, 'include_readonly');
        }
        if (includeHasExpiredPriceDate !== undefined && includeHasExpiredPriceDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeHasExpiredPriceDate, 'include_has_expired_price_date');
        }
        if (includeHasArticles !== undefined && includeHasArticles !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeHasArticles, 'include_has_articles');
        }
        if (includeHasUnavailableArticles !== undefined && includeHasUnavailableArticles !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeHasUnavailableArticles, 'include_has_unavailable_articles');
        }
        if (includeHasCcfConfigurations !== undefined && includeHasCcfConfigurations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeHasCcfConfigurations, 'include_has_ccf_configurations');
        }
        if (includeHasUnavailableCcfConfigurations !== undefined && includeHasUnavailableCcfConfigurations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeHasUnavailableCcfConfigurations, 'include_has_unavailable_ccf_configurations');
        }
        if (includeHasCustomCcfConfigurations !== undefined && includeHasCustomCcfConfigurations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeHasCustomCcfConfigurations, 'include_has_custom_ccf_configurations');
        }
        if (includeHasMtlConfigurations !== undefined && includeHasMtlConfigurations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeHasMtlConfigurations, 'include_has_mtl_configurations');
        }
        if (includeHasUnavailableMtlConfigurations !== undefined && includeHasUnavailableMtlConfigurations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeHasUnavailableMtlConfigurations, 'include_has_unavailable_mtl_configurations');
        }
        if (includeUploadsMediaSources !== undefined && includeUploadsMediaSources !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeUploadsMediaSources, 'include_uploads_media_sources');
        }
        if (includeCpdData !== undefined && includeCpdData !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeCpdData, 'include_cpd_data');
        }
        if (includePdfSections !== undefined && includePdfSections !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includePdfSections, 'include_pdf_sections');
        }
        if (excludeText !== undefined && excludeText !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeText, 'exclude_text');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse20086>(`${this.configuration.basePath}/api/prj/projects/${encodeURIComponent(String(projectId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Project lock
     * Project lock
     * @param projectId ID of project
     * @param projectAccessedAt Project updated at
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param excludeText Exclude text
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjProjectLock(projectId: number, projectAccessedAt?: string, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20086>;
    public prjProjectLock(projectId: number, projectAccessedAt?: string, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20086>>;
    public prjProjectLock(projectId: number, projectAccessedAt?: string, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20086>>;
    public prjProjectLock(projectId: number, projectAccessedAt?: string, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling prjProjectLock.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (projectAccessedAt !== undefined && projectAccessedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>projectAccessedAt, 'project_accessed_at');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (excludeText !== undefined && excludeText !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeText, 'exclude_text');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<InlineResponse20086>(`${this.configuration.basePath}/api/prj/projects/${encodeURIComponent(String(projectId))}/lock`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Project navigation
     * Project navigation
     * @param projectId ID of project
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjProjectNavigation(projectId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse200109>;
    public prjProjectNavigation(projectId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse200109>>;
    public prjProjectNavigation(projectId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse200109>>;
    public prjProjectNavigation(projectId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling prjProjectNavigation.');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse200109>(`${this.configuration.basePath}/api/prj/projects/${encodeURIComponent(String(projectId))}/navigation`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Project restore
     * Project restore
     * @param projectId ID of project
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param excludeText Exclude text
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjProjectRestore(projectId: number, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20086>;
    public prjProjectRestore(projectId: number, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20086>>;
    public prjProjectRestore(projectId: number, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20086>>;
    public prjProjectRestore(projectId: number, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling prjProjectRestore.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (excludeText !== undefined && excludeText !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeText, 'exclude_text');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<InlineResponse20086>(`${this.configuration.basePath}/api/prj/projects/${encodeURIComponent(String(projectId))}/restore`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Project sync
     * Project sync
     * @param projectId ID of project
     * @param projectAccessedAt Project updated at
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param excludeText Exclude text
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjProjectSync(projectId: number, projectAccessedAt?: string, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20086>;
    public prjProjectSync(projectId: number, projectAccessedAt?: string, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20086>>;
    public prjProjectSync(projectId: number, projectAccessedAt?: string, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20086>>;
    public prjProjectSync(projectId: number, projectAccessedAt?: string, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling prjProjectSync.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (projectAccessedAt !== undefined && projectAccessedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>projectAccessedAt, 'project_accessed_at');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (excludeText !== undefined && excludeText !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeText, 'exclude_text');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<InlineResponse20086>(`${this.configuration.basePath}/api/prj/projects/${encodeURIComponent(String(projectId))}/sync`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Project unlock
     * Project unlock
     * @param projectId ID of project
     * @param projectAccessedAt Project updated at
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param excludeText Exclude text
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjProjectUnlock(projectId: number, projectAccessedAt?: string, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20086>;
    public prjProjectUnlock(projectId: number, projectAccessedAt?: string, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20086>>;
    public prjProjectUnlock(projectId: number, projectAccessedAt?: string, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20086>>;
    public prjProjectUnlock(projectId: number, projectAccessedAt?: string, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling prjProjectUnlock.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (projectAccessedAt !== undefined && projectAccessedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>projectAccessedAt, 'project_accessed_at');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (excludeText !== undefined && excludeText !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeText, 'exclude_text');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<InlineResponse20086>(`${this.configuration.basePath}/api/prj/projects/${encodeURIComponent(String(projectId))}/unlock`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Project update
     * Project update
     * @param projectId ID of project
     * @param prjProjectInput Pass project details
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param excludeText Exclude text
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjProjectUpdate(projectId: number, prjProjectInput: PrjProjectInput, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20086>;
    public prjProjectUpdate(projectId: number, prjProjectInput: PrjProjectInput, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20086>>;
    public prjProjectUpdate(projectId: number, prjProjectInput: PrjProjectInput, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20086>>;
    public prjProjectUpdate(projectId: number, prjProjectInput: PrjProjectInput, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling prjProjectUpdate.');
        }
        if (prjProjectInput === null || prjProjectInput === undefined) {
            throw new Error('Required parameter prjProjectInput was null or undefined when calling prjProjectUpdate.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (excludeText !== undefined && excludeText !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeText, 'exclude_text');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<InlineResponse20086>(`${this.configuration.basePath}/api/prj/projects/${encodeURIComponent(String(projectId))}`,
            prjProjectInput,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Projects list
     * Projects list
     * @param search Search string
     * @param filters Filters
     * @param page Page number; use 0 to skip page counting
     * @param perPage Rows per page; use 0 for no pagination
     * @param orderBy Order field
     * @param orderDir Order direction
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param withTrashed With trashed
     * @param category Category
     * @param excludeText Exclude text
     * @param includeVariants Include variants
     * @param latestVariantProjectId Variants of
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjProjectsList(search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, withTrashed?: boolean, category?: PrjProjectsListCategory, excludeText?: boolean, includeVariants?: boolean, latestVariantProjectId?: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PrjProjectsList>;
    public prjProjectsList(search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, withTrashed?: boolean, category?: PrjProjectsListCategory, excludeText?: boolean, includeVariants?: boolean, latestVariantProjectId?: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PrjProjectsList>>;
    public prjProjectsList(search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, withTrashed?: boolean, category?: PrjProjectsListCategory, excludeText?: boolean, includeVariants?: boolean, latestVariantProjectId?: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PrjProjectsList>>;
    public prjProjectsList(search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, withTrashed?: boolean, category?: PrjProjectsListCategory, excludeText?: boolean, includeVariants?: boolean, latestVariantProjectId?: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (search !== undefined && search !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>search, 'search');
        }
        if (filters !== undefined && filters !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filters, 'filters');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (perPage !== undefined && perPage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>perPage, 'per_page');
        }
        if (orderBy !== undefined && orderBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderBy, 'order_by');
        }
        if (orderDir !== undefined && orderDir !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderDir, 'order_dir');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (withTrashed !== undefined && withTrashed !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>withTrashed, 'with_trashed');
        }
        if (category !== undefined && category !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>category, 'category');
        }
        if (excludeText !== undefined && excludeText !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeText, 'exclude_text');
        }
        if (includeVariants !== undefined && includeVariants !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeVariants, 'include_variants');
        }
        if (latestVariantProjectId !== undefined && latestVariantProjectId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>latestVariantProjectId, 'latest_variant_project_id');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PrjProjectsList>(`${this.configuration.basePath}/api/prj/projects`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
